import {TICKETS_LIST_FAILURE, TICKETS_LIST_REQUEST, TICKETS_LIST_SUCCESS} from './Constants';

export function listTicketsRequest() {
    return {
        type: TICKETS_LIST_REQUEST,
    };
}
/**
 *handle list all transaction success
 * @param {*} data data from api
 */
export function listTicketsSuccess(data) {
    return {
        type: TICKETS_LIST_SUCCESS,
        payload: data,
    };
}

/**
 *handle list all transaction fail
 * @param {*} error error from api
 */
export function listTicketsFail(error) {
    return {
        type: TICKETS_LIST_FAILURE,
        payload: error,
    };
}
