import React from 'react';
import '../../Utilities/style.css';
import '../MainNavbar/MainNavbar.css';
import './CommonNavbar.css';
import Button from '../Button/Button';
import Delete from '../../Images/delete.png';
import './CommonNavbar.css';
import Prev from '../../Images/back.png';
import Timer from '../Timer/Timer';
import {connect} from 'react-redux';
import {MATCH_STATUS_CONST} from '../../Utilities/Constants';

class CommonNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.onBackClick = this.onBackClick.bind(this);
    }

    /**
     * navigate to previous page
     */
    onBackClick() {
        this.props.history.goBack();
    }

    render() {
        const {
            title,
            location,
            matchStatus,
            upcoming,
            startTime,
            centerTitle,
            count,
            initialTime,
            direction,
            onBackClick,
            hideAll,
            onDeleteClick,
            onPublish,
            id,
            onSave,
            editPage,
            onMarkLive,
            onForfeitSave,
        } = this.props;
        return (
            <div>
                <div className="navbar">
                    <div className="commonNavbar commonnav-grid-container ">
                        <div className="grid-item disp-flex">
                            <ul className="left-header margin-0">
                                <li
                                    onClick={onBackClick ? onBackClick : this.onBackClick}
                                    className="margin-right-16 disp-flex"
                                >
                                    <img alt="" src={Prev} />
                                </li>
                                {location === 'discountCode' ? (
                                    <React.Fragment>
                                        <li className="margin-right-16 ">
                                            <div className="margin-right-16 basic-headers">Discount Code</div>
                                            <div className="basiic-data">{id}</div>
                                        </li>
                                    </React.Fragment>
                                ) : location === 'userDetails' ? (
                                    <React.Fragment>
                                        <li className="margin-right-16 ">
                                            <div className="margin-right-16 basic-headers">User ID</div>
                                            <div className="basiic-data">{id.id}</div>
                                        </li>
                                        <li>
                                            <div className="margin-right-16 basic-headers">Username</div>
                                            <div className="basiic-data">{id.name}</div>
                                        </li>
                                       {id?.clientUserName && (
                                         <li>
                                          <div className="margin-right-16 basic-headers">Client Username</div>
                                          <div className="basiic-data">{id.clientUserName}</div>
                                        </li>
                                       )}
                                    </React.Fragment>
                                ) : location === 'teamDetails' ? (
                                    <React.Fragment>
                                        <li className="margin-right-16 ">
                                            {matchStatus === 'LIVE NOW' ? <div className="live">Live</div> : null}
                                        </li>
                                        <li className="live-time">
                                            <Timer
                                                initialTime={initialTime}
                                                direction={direction}
                                                startTime={startTime}
                                            />
                                        </li>
                                    </React.Fragment>
                                ) : location === 'recordMatch' ? (
                                    <React.Fragment>
                                        <li className="margin-right-16 ">
                                            {matchStatus === 'LIVE NOW' ? <div className="live">Live</div> : null}
                                        </li>
                                        <li className="live-time">
                                            <Timer
                                                initialTime={initialTime}
                                                direction={direction}
                                                startTime={startTime}
                                            />
                                        </li>
                                    </React.Fragment>
                                ) : location === 'EditTOPublishedMatch' ? (
                                    <React.Fragment>
                                        <li className="margin-right-16 ">
                                            <div className="live">{matchStatus}</div>
                                        </li>
                                    </React.Fragment>
                                ) : location === 'refCode' ? (
                                    <div className="disp-flex">
                                        <div className="margin-right-16">
                                            <div className="margin-right-16 basic-headers">Ref Code</div>
                                            <div className="basiic-data">{id.id}</div>
                                        </div>
                                    </div>
                                ) : (
                                    <li>{title}</li>
                                )}
                            </ul>
                        </div>

                        <div className="grid-item disp-flex align-center ">
                            <ul className="margin-0">
                                <li className="center-heading">{centerTitle}</li>
                            </ul>
                        </div>
                        <div className="grid-item disp-flex align-right">
                            {location === 'Squads' ? (
                                <ul className="margin-0">
                                    {editPage ? (
                                        <React.Fragment>
                                            {!!onDeleteClick && (
                                                <li className="margin-right-16 cursor disp-flex">
                                                    <img alt="" src={Delete} onClick={onDeleteClick} />
                                                </li>
                                            )}
                                            <li className="margin-right-16">
                                                <div className="disp-flex">
                                                    <div className="margin-right-16">Squad Id</div>
                                                    <div>{id}</div>
                                                </div>
                                            </li>
                                        </React.Fragment>
                                    ) : null}
                                    {!!onSave && (
                                        <li>
                                            <Button buttonText="Save" onClick={onSave} />
                                        </li>
                                    )}
                                </ul>
                            ) : location === 'Players' ? (
                                <ul className="margin-0">
                                    {editPage ? (
                                        <React.Fragment>
                                            {!!onDeleteClick && (
                                                <li
                                                    className="margin-right-16 cursor disp-flex"
                                                    onClick={onDeleteClick}
                                                >
                                                    <img alt="" src={Delete} />
                                                </li>
                                            )}
                                            <li className="margin-right-16">
                                                <div className="disp-flex">
                                                    <div className="margin-right-16 basic-headers">Player Id</div>
                                                    <div className="basiic-data">{id}</div>
                                                </div>
                                            </li>
                                        </React.Fragment>
                                    ) : null}

                                    {!!onSave && (
                                        <li>
                                            <Button buttonText="Save" onClick={onSave} />
                                        </li>
                                    )}
                                </ul>
                            ) : location === 'FantasyScoringModels' ? (
                                <ul className="margin-0">
                                    {editPage ? (
                                        <React.Fragment>
                                            {!!onDeleteClick && (
                                                <li
                                                    className="margin-right-16 cursor disp-flex"
                                                    onClick={onDeleteClick}
                                                >
                                                    <img alt="" src={Delete} />
                                                </li>
                                            )}
                                            <li className="margin-right-16">
                                                <div className="disp-flex">
                                                    <div className="margin-right-16 basic-headers">Model Id</div>
                                                    <div className="basiic-data">{id}</div>
                                                </div>
                                            </li>
                                        </React.Fragment>
                                    ) : null}
                                    {!!onSave && (
                                        <li>
                                            <Button buttonText="Save" onClick={onSave} />
                                        </li>
                                    )}
                                </ul>
                            ) : location === 'addMatch' ? (
                                <React.Fragment>
                                    {upcoming === 'DRAFT' || upcoming === 'COMING_SOON' || !editPage ? (
                                        <ul className="margin-0">
                                            {!editPage && !!onSave ? (
                                                <li className="margin-right-16">
                                                    <Button buttonText="Save" onClick={onSave} />
                                                </li>
                                            ) : (
                                                <React.Fragment>
                                                    {!!onDeleteClick && (
                                                        <li
                                                            className="margin-right-16 cursor disp-flex"
                                                            onClick={onDeleteClick}
                                                        >
                                                            <img alt="" src={Delete} />
                                                        </li>
                                                    )}
                                                    <li className="margin-right-16">
                                                        <div className="align-left">
                                                            <div className="margin-right-16 basic-headers">
                                                                Match Id
                                                            </div>
                                                            <div className="basiic-data">{id}</div>
                                                        </div>
                                                    </li>

                                                    {!!onSave && (
                                                        <li className="margin-right-16">
                                                            <Button buttonText="Save" onClick={onSave} />
                                                        </li>
                                                    )}
                                                    {!!onPublish && (
                                                        <li>
                                                            <Button buttonText="Publish" onClick={onPublish} />
                                                        </li>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </ul>
                                    ) : upcoming === 'UPCOMING' ? (
                                        <ul className="margin-0">
                                            <li className="margin-right-16">
                                                <div className="align-left">
                                                    <div className="margin-right-16 basic-headers">Match Id</div>
                                                    <div className="basiic-data">{id}</div>
                                                </div>
                                            </li>
                                            {!!onSave && (
                                                <li className="margin-right-16">
                                                    <Button buttonText="Save" onClick={onSave} />
                                                </li>
                                            )}
                                            {!!onMarkLive && (
                                                <li>
                                                    <Button buttonText="Mark Live" onClick={onMarkLive} />
                                                </li>
                                            )}
                                        </ul>
                                    ) : upcoming === 'LOCKED' ? (
                                        <ul className="margin-0">
                                            {!!onMarkLive && (
                                                <li>
                                                    <Button buttonText="Mark Live" onClick={onMarkLive} />
                                                </li>
                                            )}
                                        </ul>
                                    ) : (
                                        <ul className="margin-0">
                                            <li className="margin-right-16">
                                                <div className="align-left">
                                                    <div className="margin-right-16 basic-headers">Match Id</div>
                                                    <div className="basiic-data">{id}</div>
                                                </div>
                                            </li>
                                            {!!onSave && (
                                                <li className="margin-right-16">
                                                    <Button buttonText="Save" onClick={onSave} />
                                                </li>
                                            )}
                                            {!!onMarkLive && upcoming === 'ENDED' && (
                                                <li>
                                                    <Button buttonText="Mark Live" onClick={onMarkLive} />
                                                </li>
                                            )}
                                        </ul>
                                    )}
                                </React.Fragment>
                            ) : location === 'addTournamentScreen' ? (
                                <li className="margin-right-16">
                                    <Button buttonText="Add Tournament" onClick={onSave} />
                                </li>
                            ) : location === 'AddContestType' ? (
                                <ul className="margin-0">
                                    {editPage ? (
                                        <React.Fragment>
                                            {!!onDeleteClick && (
                                                <li
                                                    className="margin-right-16 cursor disp-flex"
                                                    onClick={onDeleteClick}
                                                >
                                                    <img alt="" src={Delete} />
                                                </li>
                                            )}
                                            <li className="margin-right-16">
                                                <div className="disp-flex">
                                                    <div className="margin-right-16 basic-headers">Contest Type Id</div>
                                                    <div className="basiic-data">{id}</div>
                                                </div>
                                            </li>
                                        </React.Fragment>
                                    ) : null}
                                    {!!onSave && (
                                        <li className="margin-right-16">
                                            <Button buttonText="Save" onClick={onSave} />
                                        </li>
                                    )}
                                </ul>
                            ) : location === 'addContest' ? (
                                <ul className="margin-0">
                                    {editPage ? (
                                        <React.Fragment>
                                            {hideAll
                                                ? null
                                                : !!onDeleteClick && (
                                                      <li className="margin-right-16 cursor disp-flex">
                                                          <img alt="" src={Delete} onClick={onDeleteClick} />
                                                      </li>
                                                  )}
                                            <li className="margin-right-16">
                                                <div className="align-left">
                                                    <div className="margin-right-16 basic-headers">Contest Id</div>
                                                    <div className="basiic-data">{id.contest}</div>
                                                </div>
                                            </li>
                                        </React.Fragment>
                                    ) : null}
                                    <li className="margin-right-16">
                                        <div className="align-left">
                                            <div className="margin-right-16 basic-headers">Match Id</div>
                                            <div className="basiic-data">{id.match}</div>
                                        </div>
                                    </li>
                                    {hideAll
                                        ? null
                                        : !!onSave && (
                                              <li className="margin-right-16">
                                                  <Button buttonText="Save" onClick={onSave} />
                                              </li>
                                          )}
                                </ul>
                            ) : location === 'teamDetails' ? (
                                <ul className="margin-0">
                                    <li className="margin-right-16">
                                        <div className="align-left">
                                            <div className="margin-right-16 basic-headers ">Team Id</div>
                                            <div className="basiic-data">{id.teamId}</div>
                                        </div>
                                    </li>

                                    <li className="margin-right-16">
                                        <div className="align-left">
                                            <div className="margin-right-16 basic-headers">Match Id</div>
                                            <div className="basiic-data">{id.matchId}</div>
                                        </div>
                                    </li>
                                </ul>
                            ) : location === 'recordMatch' ? (
                                <ul className="margin-0">
                                    <li className="margin-right-16 disp-flex">
                                        <div className="disp-flex">
                                            <div className="margin-right-16 basic-headers">Match Id</div>
                                            <div className="basiic-data">{id}</div>
                                        </div>
                                    </li>
                                    {!!onForfeitSave && matchStatus !== MATCH_STATUS_CONST.WINNING_INITIATED && (
                                        <li>
                                            <Button buttonText="Mark Forfeited" onClick={onForfeitSave} />
                                        </li>
                                    )}
                                    {!!onSave && matchStatus !== MATCH_STATUS_CONST.WINNING_INITIATED && (
                                        <li>
                                            <Button buttonText="Mark Complete" onClick={onSave} />
                                        </li>
                                    )}
                                </ul>
                            ) : location === 'userDetails' && count !== undefined ? (
                                <div className="row-count">{count} </div>
                            ) : location === 'refCode' ? (
                                <div className="row-count">{count} </div>
                            ) : location === 'notification' ? (
                                <div>
                                    <Button buttonText="Send" onClick={onSave} />
                                </div>
                            ) : location === 'submit' ? (
                                <div>
                                    <Button buttonText="Submit" onClick={onSave} />
                                </div>
                            ) : location === 'discount' ? (
                                <ul className="margin-0">
                                    {editPage ? (
                                        <li className="margin-right-16 cursor disp-flex">
                                            {!!onDeleteClick && matchStatus !== 'INACTIVE' ? (
                                                <img alt="" src={Delete} onClick={onDeleteClick} />
                                            ) : null}
                                        </li>
                                    ) : (
                                        <li>{!!onSave && <Button buttonText="Save" onClick={onSave} />}</li>
                                    )}
                                </ul>
                            ) : location === 'addAdmin' ? (
                                <ul className="margin-0"></ul>
                            ) : location === 'admins' ? (
                                <div className="row-count">{count} </div>
                            ) : location === 'addRefCode' ? (
                                <div>
                                    {editPage ? (
                                        <ul className="margin-0">
                                            <li>
                                                <Button buttonText="Save" onClick={onSave} />
                                            </li>
                                        </ul>
                                    ) : null}
                                </div>
                            ) : location === 'AddTOGame' ? (
                                <ul className="margin-0">
                                    {editPage ? (
                                        <React.Fragment>
                                            {!!onDeleteClick && (
                                                <li
                                                    className="margin-right-16 cursor disp-flex"
                                                    onClick={onDeleteClick}
                                                >
                                                    <img alt="" src={Delete} />
                                                </li>
                                            )}
                                            <li className="margin-right-16">
                                                <div className="disp-flex">
                                                    <div className="margin-right-16 basic-headers">Game Id</div>
                                                    <div className="basiic-data">{id}</div>
                                                </div>
                                            </li>
                                        </React.Fragment>
                                    ) : null}
                                    {!!onSave && (
                                        <li className="margin-right-16">
                                            <Button buttonText="Save" onClick={onSave} />
                                        </li>
                                    )}
                                </ul>
                            ) : location === 'AddTOMatchMode' ? (
                                <ul className="margin-0">
                                    <li className="margin-right-16">
                                        {!!onSave && <Button buttonText="Save" onClick={onSave} />}
                                    </li>
                                </ul>
                            ) : location === 'AddTOMatchAttribute' ? (
                                <ul className="margin-0">
                                    {editPage ? (
                                        <React.Fragment>
                                            {!!onDeleteClick && (
                                                <li
                                                    className="margin-right-16 cursor disp-flex"
                                                    onClick={onDeleteClick}
                                                >
                                                    <img alt="" src={Delete} />
                                                </li>
                                            )}
                                            <li className="margin-right-16">
                                                <div className="disp-flex">
                                                    <div className="margin-right-16 basic-headers">Game Id</div>
                                                    <div className="basiic-data">{id}</div>
                                                </div>
                                            </li>
                                        </React.Fragment>
                                    ) : null}
                                    {!!onSave && (
                                        <li className="margin-right-16">
                                            <Button buttonText="Save" onClick={onSave} />
                                        </li>
                                    )}
                                </ul>
                            ) : location === 'AddTOMatchType' ? (
                                <ul className="margin-0">
                                    {!!onSave && (
                                        <li className="margin-right-16">
                                            <Button buttonText="Save" onClick={onSave} />
                                        </li>
                                    )}
                                </ul>
                            ) : location === 'AddTOMatch' ? (
                                <ul className="margin-0">
                                    {editPage ? (
                                        <React.Fragment>
                                            {!!onDeleteClick && (
                                                <li className="margin-right-16 cursor disp-flex">
                                                    <img alt="" src={Delete} onClick={onDeleteClick} />
                                                </li>
                                            )}
                                            <li className="margin-right-16">
                                                <div className="align-left">
                                                    <div className="margin-right-16 basic-headers">Match Id</div>
                                                    <div className="basiic-data">{id}</div>
                                                </div>
                                            </li>
                                            {!!onPublish && this.props.login.userDetails.data.role !== 'ORGANIZER' ? (
                                                <li className="margin-right-16">
                                                    <Button buttonText="Publish" onClick={onPublish} />
                                                </li>
                                            ) : null}
                                        </React.Fragment>
                                    ) : null}
                                    {!!onSave && (
                                        <li className="margin-right-16">
                                            <Button buttonText="Save" onClick={onSave} />
                                        </li>
                                    )}
                                </ul>
                            ) : location === 'EditTOPublishedMatch' ? (
                                <ul className="margin-0">
                                    {matchStatus === 'UPCOMING' ? (
                                        <React.Fragment>
                                            {!!onDeleteClick && (
                                                <li className="margin-right-16">
                                                    <Button buttonText="Cancel" onClick={onDeleteClick} />
                                                </li>
                                            )}
                                            {!!onPublish && (
                                                <li className="margin-right-16">
                                                    <Button buttonText="Make Live" onClick={onPublish} />
                                                </li>
                                            )}
                                        </React.Fragment>
                                    ) : matchStatus === 'LIVE' ? (
                                        this.props.login.userDetails.data.role === 'ORGANIZER' ? null : (
                                            !!onPublish && (
                                                <li className="margin-right-16">
                                                    <Button buttonText="Mark Complete" onClick={onPublish} />
                                                </li>
                                            )
                                        )
                                    ) : null}
                                    {this.props.login.userDetails.data.role === 'ORGANIZER'
                                        ? null
                                        : !!onSave && (
                                              <li className="margin-right-16">
                                                  <Button buttonText="Save" onClick={onSave} />
                                              </li>
                                          )}
                                </ul>
                            ) : location === 'EditTOCompletedMatch' ? (
                                <ul className="margin-0">
                                    {!!onSave && (
                                        <li className="margin-right-16">
                                            <Button buttonText="Archive" onClick={onSave} />
                                        </li>
                                    )}
                                </ul>
                            ) : location === 'AddTOOrganizer' ? (
                                <ul className="margin-0">
                                    {editPage ? (
                                        <React.Fragment>
                                            {!!onDeleteClick && (
                                                <li
                                                    className="margin-right-16 cursor disp-flex"
                                                    onClick={onDeleteClick}
                                                >
                                                    <img alt="" src={Delete} />
                                                </li>
                                            )}
                                            <li className="margin-right-16">
                                                <div className="disp-flex">
                                                    <div className="margin-right-16 basic-headers">Organizer Id</div>
                                                    <div className="basiic-data">{id}</div>
                                                </div>
                                            </li>
                                        </React.Fragment>
                                    ) : null}
                                    {!!onSave && (
                                        <li className="margin-right-16">
                                            <Button buttonText="Save" onClick={onSave} />
                                        </li>
                                    )}
                                </ul>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
    };
};

export default connect(mapStateToProps)(CommonNavbar);
