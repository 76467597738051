import {TICKETS_LIST_FAILURE, TICKETS_LIST_REQUEST, TICKETS_LIST_SUCCESS} from './Constants';

const initialState = {
    TicketsList: [],
    TicketsListRequest: false,
    TicketsListSuccess: false,
    TicketsListFail: false,
    errorMessage: '',
};

const TicketsReducer = (state = initialState, action) => {
    switch (action.type) {
        case TICKETS_LIST_REQUEST:
            return {
                ...state,
                TicketsListRequest: true,
                TicketsListSuccess: false,
                TicketsListFail: false,
                TicketsList: [],
                errorMessage: '',
            };

        case TICKETS_LIST_SUCCESS:
            return {
                ...state,
                TicketsListRequest: false,
                TicketsListSuccess: true,
                TicketsListFail: false,
                TicketsList: action.payload.data,
                errorMessage: '',
            };

        case TICKETS_LIST_FAILURE:
            return {
                ...state,
                TicketsListRequest: false,
                TicketsListSuccess: false,
                TicketsListFail: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export default TicketsReducer;
