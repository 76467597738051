import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../../Utilities/style.css';
import '../Login/login.css';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Table from '../../../Components/Table/Table';
import { debounce, next, prev } from '../../../Utilities/HelperFunctions';
import { userTransactionList } from './AxioCalls';
import { logout } from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import { TRANSACTION_STATUS_OPTIONS, TRANSACTION_TYPE_OPTIONS } from '../../../Utilities/Constants';
import { usePrevious } from '../../../Utilities/hooks';
import Dropdown from '../../../Components/Dropdown/Dropdown';
import { Grid } from '@material-ui/core';
import FilterInput from '../../../Components/FilterInput';
import Button from '../../../Components/Button/Button';
import TeamListModal from './TeamListModal/TeamListModal';
import { timeFormat } from '../../../Utilities/Helpers';

const getTransactionPageColumns = (onSelectTeam) => {
    return [
        {
            Header: 'Transaction Type',
            accessor: 'txnType',
            Cell: ({ value }) => <span className="number">{value || '-'}</span>,
        },
        {
            Header: 'External Order ID',
            accessor: 'orderDetails',
            Cell: ({ value }) => <span className="number">{value?.extOrderId || '-'}</span>,
        },

        {
            Header: 'Trasaction ID',
            accessor: 'id',
            width: 200,
            Cell: ({ value }) => <span className="number">{value || '-'}</span>,
        },

        {
            Header: 'Order ID',
            accessor: 'orderId',
            width: 200,
            Cell: ({ value }) => <span className="number">{value || '-'}</span>,
        },
        {
            Header: 'Match ID',
            accessor: 'match',
            width: 200,
            Cell: ({ value }) => <span className="number">{value || '-'}</span>,
        },
        {
            Header: 'Contest ID',
            accessor: 'contest',
            width: 200,
            Cell: ({ value }) => <span className="number">{value || '-'}</span>,
        },
        {
            Header: 'Time',
            accessor: 'createdAt',
            width: 200,
            Cell: ({ value }) => <span className="number">{timeFormat(value) || '-'}</span>,
        },
        {
            Header: 'Team Count',
            accessor: 'teams',
            width: 100,
            Cell: ({ value }) => value?.length,
        },
        {
            Header: 'Team ID',
            accessor: 'teams',
            width: 100,
            Cell: ({ value }) => (
                <span className="table-clickable" onClick={() => onSelectTeam(value)}>
                    view
                </span>
            ),
        },
        {
            Header: 'Transaction Status',
            accessor: 'status',
            width: 150,
            Cell: ({ value }) => <span className="number">{value || '-'}</span>,
        },
        {
            Header: 'Entry Fee',
            accessor: 'amount',
            width: 100,
            Cell: ({ value }) => <span className="number">{value ?? '-'}</span>,
        },
        {
            Header: 'Winning Amount',
            accessor: 'winningAmount',
            width: 100,
            Cell: (props) => <span className="number">{props.row.txnType === "WINNING" ? props.value : '-'}</span>,
        },
        {
            Header: 'Refund Amount',
            accessor: 'amount',
            width: 100,
            Cell: (props) => <span className="number">{props.row.txnType === "REFUND" ? props.value : '-'}</span>,
        },
    ];
};

const UserTransactions = ({ history }) => {
    const dispatch = useDispatch();

    const login = useSelector((state) => state.login);
    const user = useSelector((state) => state.user);
    const client = useSelector((state) => state.client);
    const prevUser = usePrevious(user);

    const initialPageOptions = {
        page: 1,
        pageSize: 15,
        searchString: null,
        sortOrder: null,
        userId: user.currentUser.user,
        txnType: '',
        extOrderId: '',
        txnId: '',
        orderId: '',
        matchId: '',
        contestId: '',
        txnStatus: null,
        teamId: '',
    };

    const [list, setList] = useState([]);
    const [pageData, setPageData] = useState({});
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [pageOptions, setPageOptions] = useState(initialPageOptions);
    const [immediate, setImmediate] = useState(true);
    const [teamIds, setTeamIds] = useState(null);

    const getTransactionListDebounce = debounce(() => {
        dispatch(userTransactionList(pageOptions, login.userDetails.data.accessToken, client.selectedClient));
    });

    const userDetails = {
        name: user.currentUser?.userName,
        id: user.currentUser?.user,
        clientUserName:user.currentUser?.extUserInfo?.username|| ""
    };

    const disableNext = pageOptions.page === pageData.lastPage;

    const onReset = () => {
        setPageOptions(initialPageOptions);
        setImmediate(true);
    };

    const onNext = () => {
        const options = next(pageOptions, pageData);
        if (options) {
            setPageOptions({ ...options });
            setLoading(true);
            setImmediate(true);
        }
    };

    const onPrev = () => {
        const options = prev(pageOptions);
        if (options) {
            setPageOptions({ ...options });
            setLoading(true);
            setImmediate(true);
        }
    };

    const handleFilters = (key, value, isImmediate) => {
        setImmediate(isImmediate);
        setPageOptions({
            ...pageOptions,
            page: 1,
            [key]: value,
        });
    };

    const onSelectTeam = (teams) => {
        setTeamIds(teams);
    };

    useEffect(() => {
        if (!login.userDetails?.data) {
            history.push('/');
            dispatch(logout());
        } else {
            getTransactionListDebounce({ immediate: immediate });
        }
    }, [login.userDetails, pageOptions, client.selectedClient]);

    useEffect(() => {
        if (user.userTransactionListSuccess && !prevUser.userTransactionListSuccess) {
            setLoading(false);
            setList(user.userTransactionList.data.data);
            setPageData(user.userTransactionList.data.pageData);
        } else if (user.userTransactionListFail && !prevUser.userTransactionListFail) {
            setLoading(false);
            setSnackbarOpen(true);
            setErrorMessage(user.errorMessage);
        }
    }, [user]);

    return (
        <DetailLayout
            location="userDetails"
            history={history}
            id={userDetails}
            centerTitle="User - Transactions"
        >
            <Snackbar open={snackbarOpen} message={errorMessage} onClose={() => setSnackbarOpen(false)} />
            <LoadingModal open={loading} />
            <TeamListModal
                teamIds={teamIds}
                onClickClose={() => setTeamIds(null)}
            />
            <Grid container spacing={1} className="mt-15 mb-15">
                <Grid item xs={2}>
                    <FilterInput
                        placeholder="External Order Id"
                        value={pageOptions?.extOrderId}
                        onChange={(value) => handleFilters('extOrderId', value, false)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FilterInput
                        placeholder="Transaction Id"
                        value={pageOptions?.txnId}
                        onChange={(value) => handleFilters('txnId', value, false)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FilterInput
                        placeholder="Order Id"
                        value={pageOptions?.orderId}
                        onChange={(value) => handleFilters('orderId', value, false)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FilterInput
                        placeholder="Match Id"
                        value={pageOptions?.matchId}
                        onChange={(value) => handleFilters('matchId', value, false)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FilterInput
                        placeholder="Contest Id"
                        value={pageOptions?.contestId}
                        onChange={(value) => handleFilters('contestId', value, false)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FilterInput
                        placeholder="Team Id"
                        value={pageOptions?.teamId}
                        onChange={(value) => handleFilters('teamId', value, false)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Dropdown
                        label="Transaction Type"
                        menuItems={TRANSACTION_TYPE_OPTIONS}
                        value={pageOptions?.txnType || ''}
                        placeholder="Transaction Type"
                        onChange={(value) => handleFilters('txnType', value, true)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Dropdown
                        label="Transaction Status"
                        menuItems={TRANSACTION_STATUS_OPTIONS}
                        value={pageOptions?.txnStatus || ''}
                        onChange={(value) => handleFilters('txnStatus', value, true)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button buttonText="Reset" onClick={onReset} />
                </Grid>
            </Grid>

            <Table
                data={list}
                columns={getTransactionPageColumns(onSelectTeam)}
                page={pageOptions.page}
                onPrevClick={onPrev}
                onNextClick={onNext}
                disableNext={disableNext}
            />
        </DetailLayout>
    );
};

export default UserTransactions;
