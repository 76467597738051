import {
    USER_LIST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    BLOCK_USER,
    BLOCK_USER_SUCCESS,
    BLOCK_USER_FAIL,
    APPROVE_KYC,
    APPROVE_KYC_SUCCESS,
    APPROVE_KYC_FAIL,
    USERTRANSACTION,
    USERTRANSACTION_SUCCESS,
    USERTRANSACTION_FAIL,
    CURRENT_USER,
    UNBLOCK_USER,
    UNBLOCK_USER_SUCCESS,
    UNBLOCK_USER_FAIL,
    USER_TRANSACTION_LIST,
    USER_TRANSACTION_LIST_SUCCESS,
    USER_TRANSACTION_LIST_FAIL,
} from './Constants';

//LIST USER
/**
 * list user api
 */
export function listUsers() {
    return {
        type: USER_LIST,
    };
}

/**
 * handle list user success
 * @param {*} data data from api
 */
export function listUsersSuccess(data) {
    return {
        type: USER_LIST_SUCCESS,
        payload: data,
    };
}
/**
 *handle list user failure
 * @param {*} error error from api
 */
export function listUsersFail(error) {
    return {
        type: USER_LIST_FAIL,
        payload: error,
    };
}

//BLOCK USER
/**
 * block user api
 */
export function blockUserRequest() {
    return {
        type: BLOCK_USER,
    };
}
/**
 *handle block user success
 * @param {*} data data from api
 */
export function blockUserSuccess(data) {
    return {
        type: BLOCK_USER_SUCCESS,
        payload: data,
    };
}
/**
 *handle block user failure
 * @param {*} error error from api
 */
export function blockUserFail(error) {
    return {
        type: BLOCK_USER_FAIL,
        payload: error,
    };
}

export function unBlockUserRequest() {
    return {
        type: UNBLOCK_USER,
    };
}

export function unBlockUserSuccess(data) {
    return {
        type: UNBLOCK_USER_SUCCESS,
        payload: data,
    };
}

export function unBlockUserFail(error) {
    return {
        type: UNBLOCK_USER_FAIL,
        payload: error,
    };
}

// approve kyc
/**
 * approve kyc api
 */
export function approveKYCRequest() {
    return {
        type: APPROVE_KYC,
    };
}
/**
 *handle approve kyc success
 * @param {*} data data from api
 */
export function approveKYCSuccess(data) {
    return {
        type: APPROVE_KYC_SUCCESS,
        payload: data,
    };
}
/**
 *handle approve kyc failure
 * @param {*} error error from api
 */
export function approveKYCFail(error) {
    return {
        type: APPROVE_KYC_FAIL,
        payload: error,
    };
}

// list transactions
/**
 * list transactions api
 */
export function userTransactionRequest() {
    return {
        type: USERTRANSACTION,
    };
}
/**
 *handle list transactions success
 * @param {*} data data from api
 */
export function userTransactionSuccess(data) {
    return {
        type: USERTRANSACTION_SUCCESS,
        payload: data,
    };
}
/**
 *handle list transactions failure
 * @param {*} error error from api
 */
export function userTransactionFail(error) {
    return {
        type: USERTRANSACTION_FAIL,
        payload: error,
    };
}

export function userTransactionListRequest() {
    return {
        type: USER_TRANSACTION_LIST,
    };
}

export function userTransactionListSuccess(data) {
    return {
        type: USER_TRANSACTION_LIST_SUCCESS,
        payload: data,
    };
}

export function userTransactionListFail(error) {
    return {
        type: USER_TRANSACTION_LIST_FAIL,
        payload: error,
    };
}


/**
 * save singe user details
 * @param {*} user user details
 */
export function currentUser(user) {
    return {
        type: CURRENT_USER,
        payload: user,
    };
}
