import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Table from '../../../Components/Table/Table';

import {connect} from 'react-redux';
import {timeFormat} from '../../../Utilities/Helpers';
import {debounce, next, prev} from '../../../Utilities/HelperFunctions';

import {getMatchList} from '../Matches/AxioCalls';
import moment from 'moment';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import FilterInput from '../../../Components/FilterInput';
import DropDown from '../../../Components/Dropdown/Dropdown';
import Button from '../../../Components/Button/Button';
import {ENTER} from '../../../Utilities/Constants';

class UserMatchDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            selectAll: false,
            list: [],
            open: false,
            adminpswd: '',
            snackbarOpen: false,
            errorMessage: '',
            loading: false,
            lading: true,
            pageOptions: {
                page: 1,
                pageSize: 20,
                listType: 'USER',
                userId: this.props.user.currentUser.user,
            },
            pageData: {},
            sortOptions: [
                {
                    value: 'id',
                    text: 'Id',
                },
                {
                    value: 'tournamentName',
                    text: 'Tournament Name',
                },
                {
                    value: 'matchName',
                    text: 'Match Name',
                },
                {
                    value: 'startTime',
                    text: 'Start Time',
                },
                {
                    value: 'mapName',
                    text: 'Map Name',
                },
                {
                    text: 'Status',
                    value: 'status',
                },
            ],
        };
    }

    componentWillMount() {
        const {login, history, client} = this.props;

        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            const {pageOptions} = this.state;
            this.props.getMatchList(pageOptions, login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    componentDidUpdate(prevProps) {
        const {match} = this.props;

        if (match.matchListRequest && !prevProps.match.matchListRequest) {
            this.setState({loading: true});
        }
        if (match.matchListSuccess && !prevProps.match.matchListSuccess) {
            this.setState({
                loading: false,
                list: match?.matchList?.data?.data,
                pageData: match?.matchList?.data?.pageData,
            });
        } else if (match.matchListFail && !prevProps.match.matchListFail) {
            this.setState({loading: false, snackbarOpen: true, errorMessage: match.errorMessage});
        }
    }

    /**
     * reset table data
     */
    onReset = () => {
        let options = {page: 1, pageSize: 20, listType: 'USER', userId: this.props.user.currentUser.user};
        this.setState({pageOptions: options}, () => {
            this.getMatchListDebounce({immediate: true});
        });
    };

    handleFilter = (field, value, debounce = true) => {
        const {pageOptions} = this.state;
        if (field === ENTER) {
            this.getMatchListDebounce({immediate: true});
            return;
        }
        pageOptions[field] = value;
        this.setState({pageOptions: {...pageOptions, page: 1}}, () => {
            this.getMatchListDebounce(debounce ? {} : {immediate: true});
        });
    };

    getMatchListDebounce = debounce(() => {
        const {login, client} = this.props;
        this.props.getMatchList(this.state.pageOptions, login.userDetails.data.accessToken, client.selectedClient);
    });

    /**
     * pagination and api call on next click
     */
    onNext() {
        const {pageData, pageOptions} = this.state;
        const {login, client} = this.props;
        let options = next(pageOptions, pageData);
        if (options) {
            this.props.getMatchList(options, login.userDetails.data.accessToken, client.selectedClient);
            this.setState({pageOptions: options});
        }
    }

    /**
     * pagination and api call on  prev click
     */
    onPrev() {
        const {pageOptions} = this.state;
        const {login, client} = this.props;
        let options = prev(pageOptions);
        if (options) {
            this.props.getMatchList(options, login.userDetails.data.accessToken, client.selectedClient);
            this.setState({pageOptions: options});
        }
    }

    /**
     * calculate time left from current time
     * @param {*} time time in miliseconds
     */
    timeLeft(time) {
        const today = moment();
        const matchdate = moment(time);
        const totalHours = matchdate.diff(today, 'hours');
        const totalminutes = matchdate.diff(today, 'minutes');

        const days = Math.floor(totalHours / 24);
        const hours = totalHours % 24;
        const mins = totalminutes % 60;
        return days + ' days ' + hours + ' hrs ' + mins + ' minutes';
    }

    render() {
        const {pageData, loading, snackbarOpen, errorMessage, list, sortOptions} = this.state;
        const {matchName, matchId, sortOrder, page} = this.state.pageOptions;

        const data = list;
        let userDetails = {};
        const {user, history,login} = this.props;
        const {userMatchDetailsMapName} = login.permissions || {}
        userDetails.name = user?.currentUser?.userName;
        userDetails.id = user?.currentUser?.user;
        let disableNext = page === pageData?.lastPage ? true : false;
        let date = new Date();
        let timestamp = moment(date).valueOf();

        const columns = [
            {
                Header: 'Match ID',
                accessor: 'id',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Tournament Name',
                accessor: 'tournamentName',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Match Name',
                accessor: 'matchName',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Start Time',
                accessor: 'createdAt',

                Cell: (props) => <span className="date-space">{timeFormat(props.value)}</span>,
            },
            {
                Header: 'Map Name',
                accessor: 'mapName',
                show:!!userMatchDetailsMapName,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'User teams',
                accessor: 'userTeamsCount',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Contests',
                accessor: 'userContestCount',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Total Paid',
                accessor: 'totalPaidAmount',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Winnings',
                accessor: 'totalWinnings',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : '₹ ' + props.value}
                    </span>
                ),
            },
            {
                Header: 'Time Left',
                accessor: 'startTime',
                Cell: (props) => (
                    <span className="number">
                        {props.value === '' ? '-' : timestamp > props.value ? '-' : this.timeLeft(props.value)}
                    </span>
                ),
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
        ];
        return (
            <DetailLayout
                location="userDetails"
                history={history}
                search={false}
                id={userDetails}
                centerTitle="User - Matches"
                count={pageData?.total}
            >
                <LoadingModal open={loading} />
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />

                <div className="filter-container my-10">
                    <FilterInput
                        placeholder="Match Name"
                        value={matchName || ''}
                        onChange={(value) => this.handleFilter('matchName', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <FilterInput
                        placeholder="Match Id"
                        value={matchId || ''}
                        onChange={(value) => this.handleFilter('matchId', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <DropDown
                        label="Sort By"
                        value={sortOrder || ''}
                        menuItems={sortOptions}
                        onChange={(value) => this.handleFilter('sortOrder', value, false)}
                    />
                    <Button buttonText="Reset" onClick={this.onReset} />
                </div>

                <Table
                    data={data}
                    columns={columns}
                    page={page}
                    onPrevClick={this.onPrev.bind(this)}
                    onNextClick={this.onNext.bind(this)}
                    disableNext={disableNext}
                />
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
        user: state.user,
        match: state.match,
        client: state.client,
    };
};

export default connect(mapStateToProps, {getMatchList, logout})(UserMatchDetails);
