import React from 'react';
import { IconButton, styled } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import styles from './TeamListModal.module.scss';
import MuiModal from '../../../../Components/MuiModal/MuiModal';
import { ColorPalette } from '../../../../Utilities/constants/color-constants';

const TeamListModal = (props) => {
    const { teamIds, onClickClose } = props;

    return (
        <MuiModal onClickClose={onClickClose} open={!!teamIds} className={styles.container}>
            <div className={styles.header}>
                <h3>Team IDs ({teamIds?.length}) </h3>
                <CustomIconButton onClick={onClickClose} className={styles.closeButton}>
                    <Close className={styles.closeButtonIcon} />
                </CustomIconButton>
            </div>
            <div className={styles.divider} />
            <div className={styles.body}>
                {teamIds?.map((teamId, index) => (
                    <p key={teamId} className="mb-5">
                        {index + 1} - {teamId}
                    </p>
                ))}
            </div>
        </MuiModal>
    );
};

const CustomIconButton = styled(IconButton)({
    width: '28px',
    height: '28px',

    '&:hover': {
        backgroundColor: ColorPalette.NIGHT_RIDER,
    },
});

export default TeamListModal;
