import axios from 'axios';

import {URL} from '../../../Utilities/Constants';
import {listTicketsRequest,listTicketsFail, listTicketsSuccess } from './action';



export function getTicketsList(user, token, clientId) {
    return (dispatch) => {
        dispatch(listTicketsRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/ticket/list`,
            data: user,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listTicketsSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(listTicketsFail(data.data.errorData.errorReason));
                } else {
                    dispatch(listTicketsFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listTicketsFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listTicketsFail(err.message || 'Something went wrong'));
                }
            });
    };
}