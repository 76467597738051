import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Typography,
  Tooltip,
  IconButton,
  Box
} from '@material-ui/core';
import CalendarToday from '@material-ui/icons/CalendarToday';
import FileCopy from '@material-ui/icons/FileCopy';
import EmojiEvents from '@material-ui/icons/EmojiEvents';
import {fetchGlacierTournaments} from './action';
import {logout} from '../Login/LoginAction';
import {next, prev, copyText} from '../../../Utilities/HelperFunctions';
import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import FilterInput from '../../../Components/FilterInput';
import Button from '../../../Components/Button/Button';
import {ENTER, GAME_UNIQUE_CODE, NEXT, SORT_ORDER_OP, TOURNAMENT_STATUS_OP, TOURNAMENT_TIERS} from '../../../Utilities/Constants';
import {useDebounce, usePrevious} from '../../../Utilities/hooks';
import DropDown from '../../../Components/Dropdown/Dropdown';
import {getTOGameList} from '../TOGame/AxioCalls';
import Pagination from '../../../Components/Pagination';
import DateRangeSelect from '../../../Components/DateRangeSelect';
import ToCalendarMatchesTable from './ToCalendarMatchesTable/ToCalendarMatchesTable';
import ToCalendarSeriesTable from './ToCalendarSeriesTable/ToCalendarSeriesTable';
import MuiButton from '../../../Components/MuiButton/MuiButton';
import ImportTournamentModal from '../FantasyTournament/ImportTournamentModal/ImportTournamentModal';
import Modal from '../../../Components/Modal/Modal'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#1e1e1e',
  color: theme.palette.common.white,
  fontSize: 14,
  padding: '10px 16px',
  borderBottom: '1px solid #333',
}));
const StyledHeaderCell = styled(StyledTableCell)(({ theme }) => ({
    fontSize: 16,
    color: theme.palette.common.white,
    backgroundColor: '#333',
    position: 'sticky',
    top: 0,
    zIndex: 1,
  }));

const StyledTableRow = styled(TableRow)(({ theme, index }) => ({
  backgroundColor: index % 2 === 0 ? '#1e1e1e' : '#2a2a2a',
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const HighlightedText = styled(Typography)({
  fontSize: 12,
  color: '#ffd700',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
});
const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

const ToCalendar = (props) => {
    const {history} = props;
    const initialFilter = {offset: 0, limit: 20};
    const dispatch = useDispatch();
    const client = useSelector((state) => state.client);
    const login = useSelector((state) => state.login);
    const toCalendar = useSelector((state) => state.toCalendar);
    const prevToCalendar = usePrevious(toCalendar);
    const toGame = useSelector((state) => state.toGame);
    const prevToGame = usePrevious(toGame);
    const [gameOptions, setGameOptions] = useState([]);
    const [filters, setFilters] = useState(initialFilter);
    const {limit, offset, immediate, sorting, ..._filters} = filters;
    const debouncedFilters = useDebounce(filters, {immediate: immediate});
    const [state, setState] = useState({});
    const [tournaments, setTournaments] = useState({});
    const [tournamentId,setTournamentId]=useState(null)
    const [importTournamentModal, setImportTournamentModal] = useState(null);
    const [gameType, setGameType] = useState('');
    const [showTounamentDetails,setShowTounamentDetails]=useState(false)

    const page = offset / limit + 1;
    const lastPage = Math.ceil((tournaments?.count || 0) / limit) || 1;

    const {errorMsg} = state;
    const loading = !!toCalendar.isFetchingGlacierTO || false;

    useEffect(() => {
        if (!login?.userDetails?.data) {
            history.push('/');
            dispatch(logout());
            return;
        }
        dispatch(getTOGameList({listType: 'ALL'}, login?.userDetails?.data?.accessToken, client.selectedClient));
    }, []);

    useEffect(() => {
        if (login?.userDetails?.data) handleFetchGlacierTo();
    }, [debouncedFilters]);

    useEffect(() => {
        if (toCalendar.isFetchingGlacierTOSuccess && !prevToCalendar.isFetchingGlacierTOSuccess) {
            setTournaments(toCalendar?.glacierTO || {});
        }

        if (toCalendar.isFetchingGlacierTOFail && !prevToCalendar.isFetchingGlacierTOFail) {
            handleOnStateChange({errorMsg: toCalendar.message.msg});
        }

        if (toGame.TOGameListSuccess && !prevToGame.TOGameListSuccess) {
            const temp = toGame?.TOGameList?.data?.data?.map((item) => ({text: item.gameName, value: item.id}));
            setGameOptions(temp);
        }
        if (toGame.TOGameListFail && !prevToGame.TOGameListFail) {
            handleOnStateChange({errorMsg: toGame.errorMessage});
        }
    }, [toCalendar, toGame]);

    const handleFetchGlacierTo = () => {
        const query = {};
        if (sorting) {
            query.sort = sorting?.split('/')?.[0];
            query.order = sorting?.split('/')?.[1];
        }
        dispatch(fetchGlacierTournaments({filters: _filters, offset, limit, detailed: true, ...query}));
    };

    const onReset = () => {
        setFilters({...initialFilter, immediate: true});
        setShowTounamentDetails(false)     
    };

    const onNextORPrev = (action) => {
        const pageObj = action === NEXT ? next({page}, {lastPage}) : prev({page});
        if (pageObj) setFilters({...filters, offset: limit * (pageObj?.page - 1), immediate: true});
    };

    const handleFilter = (field, value, debounce = true) => {
        const tempFilter = {};
        if (field === 'dates') {
            tempFilter.start = value.start ? moment(value.start).endOf('day').valueOf() : null;
            tempFilter.end = value.end ? moment(value.end).startOf('day').valueOf() : null;
        } else if (field !== ENTER) tempFilter[field] = value;
        setFilters({...filters, ...tempFilter, offset: 0, immediate: !debounce});
    };

    const handleOnStateChange = (stateObj = {}) => {
        setState((prevState) => ({...prevState, ...stateObj}));
    };

    const handleCopy = (text) => {
        copyText(text, (msg) => handleOnStateChange({errorMsg: msg}));
    };
   
    const handleShow = (id,game)=>{
        setTournamentId(id)
        const games5V5 = [
            GAME_UNIQUE_CODE.csgo,
            GAME_UNIQUE_CODE.val,
            GAME_UNIQUE_CODE.dota,
            GAME_UNIQUE_CODE.pokemonUnite,
            GAME_UNIQUE_CODE.codm,
            GAME_UNIQUE_CODE.codpc,
        ];
        if (games5V5.includes(game?.gameUniqueCode)) {
            setGameType('5V5')  
        } else {
            setGameType('SQUAD')
        }
        setShowTounamentDetails(!showTounamentDetails)
    }

    const onTournamentImportSuccess = () => {
        setImportTournamentModal(null);
    };

    return (
        <TableLayout location="TO Calendar" history={history} hideSearchBar>
            {!!loading && <LoadingModal open={!!loading} />}
            {!!errorMsg && (
                <Snackbar open={!!errorMsg} message={errorMsg} onClose={() => handleOnStateChange({errorMsg: ''})} />
            )}
            {!!importTournamentModal && (
                <ImportTournamentModal
                    open={!!importTournamentModal}
                    onClickClose={() => setImportTournamentModal(null)}
                    onTournamentImportSuccess={onTournamentImportSuccess}
                />
            )}

            <div className="filter-container my-10">
                <FilterInput
                    placeholder="Name"
                    value={filters.name || ''}
                    onChange={(value) => handleFilter('name', value)}
                    onEnter={() => handleFilter(ENTER, '', false)}
                />
                <DateRangeSelect
                    label="Date range"
                    startDate={filters?.start}
                    endDate={filters?.end}
                    isClearable={true}
                    onChange={(dates) => handleFilter('dates', dates, false)}
                />
                <DropDown
                    label="Game"
                    value={filters.gameId || ''}
                    menuItems={gameOptions || []}
                    onChange={(value) => handleFilter('gameId', value, false)}
                />
                <DropDown
                    label="Tier"
                    value={filters.tier || ''}
                    menuItems={TOURNAMENT_TIERS}
                    onChange={(value) => handleFilter('tier', value, false)}
                />
                <DropDown
                    label="Status"
                    value={filters.status || ''}
                    menuItems={TOURNAMENT_STATUS_OP}
                    onChange={(value) => handleFilter('status', value, false)}
                />
                <DropDown
                    label="Sorting"
                    value={filters.sorting || ''}
                    menuItems={SORT_ORDER_OP}
                    onChange={(value) => handleFilter('sorting', value, false)}
                />
                <MuiButton buttonText="Import Tournament" onButtonClick={() => setImportTournamentModal({})} />
                <Button buttonText="Reset" onClick={onReset} />
            </div>
            <TableContainer component={Paper} sx={{maxHeight: '70vh', overflowY: 'auto', bgcolor: '#121212'}}>
                <Table stickyHeader aria-label="tournament table">
                    <TableHead>
                        <TableRow>
                            <StyledHeaderCell>Tournament Name</StyledHeaderCell>
                            <StyledHeaderCell>Location</StyledHeaderCell>
                            <StyledHeaderCell>Tier</StyledHeaderCell>
                            <StyledHeaderCell>Start Date</StyledHeaderCell>
                            <StyledHeaderCell>Status</StyledHeaderCell>
                            <StyledHeaderCell>Tournament ID</StyledHeaderCell>
                            <StyledHeaderCell>Prize Pool</StyledHeaderCell>
                            <StyledHeaderCell>Game</StyledHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(tournaments?.list || []).map((tournament, index) => {
                            const game = toGame?.TOGameList?.data?.data?.find((g) => g.id === tournament.gameId) || {};
                            return (
                                <React.Fragment key={tournament.id}>
                                    <StyledTableRow index={index}>
                                        <StyledTableCell>
                                            <HighlightedText onClick={() => handleShow(tournament.id,game)} variant="body2">{tournament.name}</HighlightedText>
                                        </StyledTableCell>
                                        <StyledTableCell>{`${tournament?.country?.name || '-'} | ${tournament?.region?.name || '-'}`}</StyledTableCell>
                                        <StyledTableCell>{tournament.tier}</StyledTableCell>
                                        <StyledTableCell>
                                            {`${moment(tournament.start).format('Do MMM YYYY')}`}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {tournament.status
                                                ? tournament.status.charAt(0).toUpperCase() +
                                                  tournament.status.slice(1).toLowerCase()
                                                : 'Status Unavailable'}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Tooltip title="Copy ID">
                                                <IconButton onClick={() => handleCopy(tournament.id)} size="small">
                                                    <FileCopy fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                            {tournament.id}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {/* <Tooltip title={`${tournament?.prizePool?.currency} ${tournament?.prizePool?.value}`}> */}
                                            {/* <EmojiEvents fontSize="small" /> */}
                                            {tournament?.prizePool?.value
                                                ? `${tournament?.prizePool?.currency} ${tournament?.prizePool?.value}`
                                                : 'NA'}
                                            {/* </Tooltip> */}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {game?.gameName ? (
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <img
                                                        src={game.gameIconImage}
                                                        alt={game.gameName}
                                                        style={{width: '35px', marginRight: '10px'}}
                                                    />
                                                    {game.gameName}
                                                </div>
                                            ) : (
                                                'NA'
                                            )}
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    {showTounamentDetails && tournamentId === tournament.id && (
                                        <Modal open={showTounamentDetails} modalStyle={modalStyle}>
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={8}>
                                                    {gameType === '5V5' && (
                                                        <ToCalendarSeriesTable
                                                            tournamentId={tournament.id}
                                                            dateRange={filters.dateRange}
                                                            setShowTounamentDetails={setShowTounamentDetails}
                                                            showTounamentDetails={showTounamentDetails}
                                                        />
                                                    )}
                                                    {gameType === 'SQUAD' && (
                                                        <ToCalendarMatchesTable
                                                            tournamentId={tournament.id}
                                                            setShowTounamentDetails={setShowTounamentDetails}
                                                            showTounamentDetails={showTounamentDetails}
                                                        />
                                                    )}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </Modal>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <Pagination
                page={page}
                disableNext={page === lastPage}
                onNextClick={() => onNextORPrev(NEXT)}
                onPrevClick={onNextORPrev}
            />
        </TableLayout>
    );
};

export default ToCalendar;
